import { Box, FormHelperText, Grid } from "@mui/material";
import React, { FC } from "react";
import TextEntryPointInboxSettingsSection from "./EmailEntryPointInboxSettingsSection";
import { useStyles } from "src/theme/styles";
import { useTranslation } from "react-i18next";
import { CommonEntryPointBox } from "../commonEntryPoints/CommonEntryPointBox";
import {
	TextEntryPoint,
	EmailEntryPointMappingsContainerProps,
} from "src/types/textEntryPoints";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useFormikContext } from "formik";

const TextEntryPointInboxSettingsContainer: FC<EmailEntryPointMappingsContainerProps> =
	React.forwardRef((props, ref) => {
		const mappingProps = props[
			"entryPointMappingsContainerProps"
		] as EmailEntryPointMappingsContainerProps;
		const classes = useStyles();
		const { t } = useTranslation();

		const { setFieldValue } = useFormikContext<TextEntryPoint>();

		return (
			<Box className={classes.entryPointSectionContainer}>
				<Grid item container xs={12} spacing={2}>
					<Grid item xs={12} className={classes.emailEntryPointInboxContainerInfo}>
						{t("TextEntryPoint.EmailFoldersExplanation")
							.split("\n")
							.map((line, index) => (
								<p key={index}>{line}</p>
							))}
					</Grid>
					<Grid item xs={6}>
						<CommonEntryPointBox
							label={t("TextEntryPoint.EmailFolders")}
							id="email-entry-point-box"
							InputProps={{
								inputComponent: TextEntryPointInboxSettingsSection,
								inputProps: {
									entryPointMappingsSectionProps: {
										...mappingProps,
										folders: mappingProps.folders,
										propertyName: "folders",
									},
								},
								readOnly: true,
							}}
							InputLabelProps={{ shrink: true }}
							className={classes.entryPointBox}
						/>
						{mappingProps.touched.folders &&
							mappingProps.errors.folders &&
							typeof mappingProps.errors.folders === "string" && (
								<Box>
									<FormHelperText error>
										{mappingProps.errors.folders}
									</FormHelperText>
								</Box>
							)}
					</Grid>
					<Grid item xs={6}>
						<DateTimePicker
							minutesStep={1}
							ampm={false}
							value={mappingProps.processingStartDate}
							slotProps={{
								textField: {
									name: "processingStartDate",
									className: classes.customTextField,
									error: Boolean(mappingProps.errors.processingStartDate),
									helperText: mappingProps.errors.processingStartDate,
									size: "small",
									"data-testid": "processingStartDate",
								} as any,
							}}
							name="processingStartDate"
							onChange={(value) => {setFieldValue("processingStartDate", value)}}
							onError={(error) => {}}
							label={t("TextEntryPoint.ProcessingStartDate")}
							format={"DD/MM/YYYY HH:mm"}
							timezone="UTC"
						/>
					</Grid>
				</Grid>
			</Box>
		);
	});

export default TextEntryPointInboxSettingsContainer;
