import { styled, TextField } from "@mui/material";

export const CommonEntryPointBox = styled(TextField)({
    [`> .MuiOutlinedInput-root > fieldset, > .MuiOutlinedInput-root:hover > fieldset, > .MuiOutlinedInput-root:hover > .MuiOutlinedInput-notchedOutline, 
        > .MuiOutlinedInput-root:hover .entryPointBox > .MuiOutlinedInput-root > fieldset,  
        > .MuiOutlinedInput-root:hover .entryPointBox > .MuiOutlinedInput-root > .MuiOutlinedInput-notchedOutline `]: {
        borderColor: '#00bcd4',
    },
    '> .MuiOutlinedInput-root .entryPointBox': {
        width: '100%'
    },
    'label': {
        color: '#00bcd4'
    }
});