import { Box, Grid, IconButton } from "@mui/material";
import { FC } from "react";
import { useStyles } from "src/theme/styles";
import { CustomTextField } from "../shared/CustomTextField";
import BlockIcon from '@mui/icons-material/Block';

const EmailEntryPointParameterRow: FC<any> = (props) => {
    const { parameter, onDelete, handleBlur, handleChange, index, containerName, errors, touched } = props;
    const classes = useStyles();
    return (
        <Box className={classes.rowItem}>
            <Grid item xs={12} className={classes.rowField} data-testid={`${containerName}Parameter-${index}`}>
                <CustomTextField
                    className={classes.rowInput}
                    size="small"
                    color="info"
                    key={`parameter-value-${parameter.id}`}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={parameter.name}
                    variant="outlined"
                    name={`${containerName}.${index}.name`}
                    error={!!touched && touched.name && !!errors && !!errors.name}
                    helperText={!!touched && !!touched.name && errors ? errors.name : null}
                    data-testid={`${containerName}ParameterValue`}
                />
            </Grid>
            <IconButton
                className={classes.deleteButton}
                onClick={onDelete}
            >
                <BlockIcon className={classes.declineButton} />
            </IconButton>
        </Box>
    );
};

export default EmailEntryPointParameterRow;
