import { Grid } from "@mui/material";
import React from "react";
import { TextEntryPoint } from "src/types/textEntryPoints";
import { CustomTextField } from "../shared/CustomTextField";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

export const WhatsappPhoneNumberFields = React.forwardRef((_props) => {
	const { t } = useTranslation();

	const { values, touched, errors, handleBlur, handleChange } =
		useFormikContext<TextEntryPoint>();

	return (
		<>
			<Grid item xs={5}>
				<CustomTextField
					size="small"
					color="info"
					error={Boolean(touched.phoneNumberId && errors.phoneNumberId)}
					fullWidth
					helperText={touched.phoneNumberId && errors.phoneNumberId}
					label={t("TextEntryPoint.PhoneNumberId")}
					name="phoneNumberId"
					onBlur={handleBlur}
					onChange={handleChange}
					value={values.phoneNumberId}
					variant="outlined"
					data-testid="phoneNumberId"
				/>
			</Grid>
			<Grid item xs={5}>
				<CustomTextField
					size="small"
					color="info"
					error={Boolean(touched.phoneNumber && errors.phoneNumber)}
					fullWidth
					helperText={touched.phoneNumber && errors.phoneNumber}
					label={t("BotApplicationInstance.PhoneNumber")}
					name="phoneNumber"
					onBlur={handleBlur}
					onChange={handleChange}
					value={values.phoneNumber}
					variant="outlined"
					data-testid="phoneNumber"
				/>
			</Grid>
		</>
	);
});
