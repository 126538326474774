import { ApiGeneralSettings } from ".";
import { AxiosResponse } from 'axios';
import { HttpMethods } from "../httpMethods";
import { Controllers, GeneralSettingsRoutes } from "../../utils/constants";
import { Webhook } from "src/types/webhook";
import { Reason } from "src/types/reason";
import { Key } from "src/types/key";
import { BaseService } from "../baseService";

export class GeneralSettingsService extends BaseService implements ApiGeneralSettings {
    
    async getWebhooks(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.GeneralSettings, GeneralSettingsRoutes.Webhooks);
    }

    async updateWebhooks(webhooks: Webhook[]): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.GeneralSettings, GeneralSettingsRoutes.UpdateWebhooks, null, webhooks);
    }

    async getReasons(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.Reason, GeneralSettingsRoutes.Reasons);
    }

    async updateReasons(webhooks: Reason[]): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.Reason, GeneralSettingsRoutes.UpdateReasons, null, webhooks);
    }

    getKeys(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.Key, GeneralSettingsRoutes.Keys);
    }

    updateKeys(keys: Key[]) {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.Key, GeneralSettingsRoutes.Keys, null, keys);
    }

    async resyncTenant(tenantId: string): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.TenantManager, GeneralSettingsRoutes.Resync, `?tenantId=${tenantId}`);
    }

    async refreshContract(tenantId: string): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.TenantManager, GeneralSettingsRoutes.RefreshContract, `?tenantId=${tenantId}`);
    }

    async resyncAllTenants(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.TenantManager, GeneralSettingsRoutes.ResyncAll, null);
    }

    async getPermissions(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.GeneralSettings, GeneralSettingsRoutes.Permissions);
    }

    async checkIfSkillsAreEnabled(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.GeneralSettings, GeneralSettingsRoutes.SkillsEnabled);
    }
}