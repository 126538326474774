import { Button, Box } from "@mui/material";
import React, { FC } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { EntryPointMappingsSectionProps } from "src/types/genericEntryPoint";
import { useTranslation } from "react-i18next";
import { useStyles } from "src/theme/styles";
import { FieldArray, FieldArrayRenderProps } from "formik";
import EmailEntryPointParameterRow from "./EmailEntryPointParameterRow";
import { EmailEntryPointMappingsSectionProps, EmailFolder } from "src/types/textEntryPoints";

const TextEntryPointInboxSettingsSection: FC<EntryPointMappingsSectionProps> = React.forwardRef((props, ref) => {
    const mappingProps = props['entryPointMappingsSectionProps'] as EmailEntryPointMappingsSectionProps;
    const { t } = useTranslation();
    const classes = useStyles();

    const addParameter = (arrayHelpers: FieldArrayRenderProps) => {
        const newParameter: EmailFolder = {
            id: '',
            name: ''
        };
        arrayHelpers.push(newParameter);
    };
    return (
        <FieldArray name={mappingProps.propertyName} render={arrayHelpers => (
            <Box className={classes.entryPointSectionContainer}>
                {mappingProps.folders && mappingProps.folders.map((item, index) => (
                    <EmailEntryPointParameterRow
                        index={index}
                        key={item.id}
                        parameter={item}
                        handleChange={mappingProps.handleChange}
                        handleBlur={mappingProps.handleBlur}
                        onDelete={() => arrayHelpers.remove(index)}
                        containerName={mappingProps.propertyName}
                        errors={mappingProps.errors[mappingProps.propertyName] ? mappingProps.errors[mappingProps.propertyName][index] : null}
                        touched={mappingProps.touched[mappingProps.propertyName] ? mappingProps.touched[mappingProps.propertyName][index] : null}
                    />
                ))}
                <Button
                    color="primary"
                    startIcon={<AddCircleIcon />}
                    variant="contained"
                    onClick={() => addParameter(arrayHelpers)}
                    data-testid={`add${mappingProps.propertyName}`}
                >
                    {t('Shared.Add')}
                </Button>
            </Box>
        )} />
    );
});

export default TextEntryPointInboxSettingsSection;
