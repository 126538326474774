import { Dayjs } from 'dayjs';
import { AssignToDataItem } from './botApplicationInstance';
import { AssignedTypeEnum } from './shared';

export interface TextEntryPoint {
    id?: string;
    identifier: string;
    name: string;
    email?: string;
    queueId?: string;
    flowId?: string;
    assignedMediaName?: string;
    type?: TypeEnum;
    accessToken?: string,
    businessAccountId: string,
    phoneNumber?: string,
    phoneNumberId?: string,
    url?: string,
    processingStartDate: string;
    folders: EmailFolder[]
}

export interface EmailFolder {
    id?: string;
    name: string;
}

export const TextEntryPointAssignedTypeToLabelMapping: Record<AssignedTypeEnum, string> = {
    [AssignedTypeEnum.Queue]: "TextEntryPoint.Queue",
    [AssignedTypeEnum.Flow]: "TextEntryPoint.Flow"
}

export interface EntryPointAssignments {
    queues: AssignToDataItem[];
    flows: AssignToDataItem[];
}

export enum TypeEnum {
    Email = 2,
    WhatsApp = 3
}

export interface EmailEntryPointMappingsSectionProps extends EmailEntryPointMappingsContainerProps {
    propertyName: string;
}

export interface EmailEntryPointMappingsContainerProps {
    handleChange: any;
    touched: any;
    errors: any;
    handleBlur: any;
    folders: EmailFolder[];
    processingStartDate: Dayjs;
}