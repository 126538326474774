import { DateTimeFormat } from "./constants";
import { parseInt } from "lodash";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("UTC");

export const getDateNowHourPrecision = () => {
	const tzOffset = new Date().getTimezoneOffset();
	const utcNow = dayjs.utc().startOf("hour");
	const adjustedDate = utcNow.subtract(tzOffset, "minute");
	return adjustedDate;
};

export const getDateNowMinutesPrecision = () => {
	const tzOffset = new Date().getTimezoneOffset();
	const utcNow = dayjs.utc().startOf("minute");
	const adjustedDate = utcNow.subtract(tzOffset, "minute");
	return adjustedDate;
};

export const getDateNowAsString = () => {
	const dateNow = getDateNowHourPrecision();
	const result = dateNow.format(DateTimeFormat.DefaultFormat);

	return result;
};

export const getDateTomorow = () => {
	return getDateNowHourPrecision().add(1, "day");
};

export const getDateTomorowAsString = () => {
	return getDateTomorow().format(DateTimeFormat.DefaultFormat);
};

export const getDate = (
	date: string,
	format: string = DateTimeFormat.DefaultFormat,
	isTomorrow: boolean = false
) => {
	//#waring-cs should save the dates in utc time in db
	let utcDate = dayjs.utc(date, DateTimeFormat.DefaultFormat);
	const dayJsResult = isTomorrow ? utcDate.add(1, "day") : utcDate;
	return dayJsResult;
};

export const toFormatedString = (date: Dayjs) => {
	if (date === null) {
		return "";
	}
	return dayjs(date).format(DateTimeFormat.DefaultFormat);
};

export const dateCompare = (firstDate: string, secondDate: string) => {
	const first = dayjs(firstDate);
	const second = dayjs(secondDate);

	return first.diff(second);
};

export const buildOnlyTimeDate = (timeString: string) => {
	if (timeString === null || timeString === "") {
		return null;
	}
	const timeSplit = timeString.split(":");
	if (timeSplit.length >= 2) {
		const dateObject = new Date();
		dateObject.setHours(parseInt(timeSplit[0]));
		dateObject.setMinutes(parseInt(timeSplit[1]));
		return dayjs.utc(dateObject);
	}
	return null;
};

export const toOnlyTimeFormatedDateString = (timeString: string) => {
	return toFormatedString(buildOnlyTimeDate(timeString));
};

export const getDayJsObjectFromIntervalValue = (value: string) => {
	if (!value) {
		return null;
	}
	const hourString = value.split(":")[0];
	const minuteString = value.split(":")[1];

	return dayjs().hour(parseInt(hourString)).minute(parseInt(minuteString));
};
