import { useSnackbar } from "notistack";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import { emailEntryPointService } from "src/services/emailEntryPoints";
import { useStyles } from "src/theme/styles";
import { MenuRoutes } from "src/types/routes";
import { AssignedTypeEnum } from "src/types/shared";
import {
	EmailFolder,
	EntryPointAssignments,
	TextEntryPoint,
	TextEntryPointAssignedTypeToLabelMapping,
	TypeEnum,
} from "src/types/textEntryPoints";
import {
	MessagingEntryPointTranslationKeys,
	HTTPSTATUSCODES,
	SharedTranslationKeys,
	EmailEntryPoint,
} from "src/utils/constants";
import { getEnumValues } from "src/utils/enumHelper";
import { Loader } from "../shared";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import * as Yup from "yup";
import ChangesFormActions from "../shared/formChanges/ChangesFormActions";
import {
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
} from "@mui/material";
import { CustomTextField } from "../shared/CustomTextField";
import UtilsHelper from "src/utils/utils-helper";
import { StorageKeys } from "src/utils/storageKeys";
import Lockr from "lockr";
import { apiConfig } from "src/config";
import { whatsAppEntryPointService } from "src/services/whatsAppEntryPoints";
import { useSearchParams } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { CommonEntryPointBox } from "../commonEntryPoints/CommonEntryPointBox";
import TextEntryPointInboxSettingsContainer from "./EmailEntryPointInboxSettingsContainer";
import { getDateNowMinutesPrecision } from "src/utils/dateTimeHelper";
import { WhatsappPhoneNumberFields } from "./WhatsappPhoneNumberFields";
import dayjs from "dayjs";

const AddEditTextEntryPoint: FC = () => {
	const { entryPointId } = useParams();
	const [searchParams, _] = useSearchParams();
	const { t } = useTranslation();
	const classes = useStyles();
	const [entryPoint, setEntryPoint] = useState<TextEntryPoint | null>(
		{} as TextEntryPoint
	);
	const isMountedRef = useIsMountedRef();
	const { enqueueSnackbar } = useSnackbar();
	const tenantId = Lockr.get(StorageKeys.TenantId) || "";

	const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
	const [areAssignmentsLoaded, setAreAssignmentsLoaded] =
		useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [accessTokenEditEnabled, setAccessTokenEditEnabled] =
		useState<boolean>(true);

	const assignedType = useRef<string | TypeEnum>("");
	const [allAssignToData, setAllAssignToData] = useState<EntryPointAssignments>(
		{} as EntryPointAssignments
	);

	const navigate = useNavigate();
	const route = `${MenuRoutes.Text}/${MenuRoutes.EntryPoints}`;
	const type = +searchParams.get("type");
	const maxNameLengthLabel = "Settings.MaxNameLength";
	const getEntryPoint = async () => {
		if (entryPointId) {
			try {
				const response =
					type === TypeEnum.Email
						? await emailEntryPointService.getEmailEntryPointById(entryPointId)
						: await whatsAppEntryPointService.getWhatsAppEntryPointById(
								entryPointId
						  );

				if (
					isMountedRef.current &&
					response?.status == HTTPSTATUSCODES.StatusCodeSuccess
				) {
					setEntryPoint(response.data);
					setIsDataLoaded(true);
					setAccessTokenEditEnabled(false);
				}
			} catch (error) {
				console.error(error);
			}
		}
	};

	useEffect(() => {
		getEntryPoint();
	}, [entryPointId]);

	const getEntryPointTypes = () => {
		const permissions = Lockr.get(StorageKeys.Permissions);
		const types = getEnumValues(TypeEnum);

		if (!permissions?.whatsAppEnabled) {
			return types.filter((x) => x == TypeEnum.Email);
		}

		return types;
	};
	const entryPointTypes = useMemo(() => getEntryPointTypes(), []);

	const getAssignedTypes = () => {
		const permissions = Lockr.get(StorageKeys.Permissions);
		const isWhatsAppEntryPoint =
			entryPoint?.type === TypeEnum.WhatsApp ||
			assignedType.current === TypeEnum.WhatsApp;
		const types = getEnumValues(AssignedTypeEnum);

		const filteredTypes = isWhatsAppEntryPoint
			? types.filter((at) => at === AssignedTypeEnum.Queue)
			: types;

		if (permissions?.textFlowsEnabled === false) {
			return filteredTypes.slice(0, 1);
		}

		return filteredTypes;
	};
	const assignedTypes = useMemo(
		() => getAssignedTypes(),
		[entryPoint, assignedType.current]
	);

	const getAssignableQueues = async (type: TypeEnum): Promise<any> => {
		try {
			const response = await emailEntryPointService.getAssignToData(type);

			if (response?.status === HTTPSTATUSCODES.StatusCodeSuccess) {
				setAllAssignToData(response.data);
				setAreAssignmentsLoaded(true);
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (entryPoint.type !== undefined) {
			getAssignableQueues(entryPoint.type);
		}
	}, [entryPoint]);

	const onSuccessfulAddOrUpdate = (translationKey: string) => {
		enqueueSnackbar(t(`TextEntryPoint.${translationKey}`), {
			variant: "success",
		});
		setIsSubmitting(false);
		navigate(route);
	};

	const addOrUpdateEmailEntryPoint = async (
		emailEntryPoint: TextEntryPoint,
		isUpdate: boolean
	): Promise<void> => {
		setIsSubmitting(true);

		try {
			const response = isUpdate
				? await emailEntryPointService.updateEmailEntryPoint(emailEntryPoint)
				: await emailEntryPointService.createEmailEntryPoint(emailEntryPoint);

			if (response?.status === HTTPSTATUSCODES.StatusCodeSuccess) {
				const messageKey = isUpdate ? "UpdateSucceeded" : "CreateSucceeded";
				onSuccessfulAddOrUpdate(messageKey);
			}
		} catch (error) {
			switch (error?.response?.status) {
				case HTTPSTATUSCODES.StatusCodeBadRequest: {
					const errorData = error.response.data;
					let errorMessage;

					if (Array.isArray(errorData)) {
						errorMessage = errorData.join(", ");
					} else {
						errorMessage = errorData;
					}
					enqueueSnackbar(
						`${t("TextEntryPoint.InvalidFolderNames")}: ${errorMessage}`,
						{
							variant: "error",
						}
					);
					break;
				}
				case HTTPSTATUSCODES.StatusCodeNotFound: {
					enqueueSnackbar(t("TextEntryPoint.InvalidIdentifierId"), {
						variant: "error",
					});
					break;
				}
				case HTTPSTATUSCODES.StatusCodeForbidden: {
					enqueueSnackbar(t("TextEntryPoint.EntityDoesNotExist"), {
						variant: "error",
					});
					break;
				}
				case HTTPSTATUSCODES.StatusCodeConflict: {
					enqueueSnackbar(
						t("TextEntryPoint.EmailEntityWithSamePropertiesExists"),
						{ variant: "error" }
					);
					break;
				}
				default: {
					const messageKey = isUpdate ? "UpdateFailed" : "CreateFailed";
					enqueueSnackbar(t(`TextEntryPoint.${messageKey}`), {
						variant: "error",
					});
				}
			}
			setIsSubmitting(false);
			console.error(error);
		}
	};

	const addOrUpdateWhatsappEntryPoint = async (
		whatsappEntryPoint: TextEntryPoint,
		isUpdate: boolean
	) => {
		try {
			const response = isUpdate
				? await whatsAppEntryPointService.updateWhatsAppEntryPoint(
						whatsappEntryPoint
				  )
				: await whatsAppEntryPointService.createWhatsAppEntryPoint(
						whatsappEntryPoint
				  );

			if (response?.status === HTTPSTATUSCODES.StatusCodeSuccess) {
				const messageKey = isUpdate ? "UpdateSucceeded" : "CreateSucceeded";
				onSuccessfulAddOrUpdate(messageKey);
			}
		} catch (error) {
			if (error?.response?.status === HTTPSTATUSCODES.StatusCodeConflict) {
				enqueueSnackbar(
					t("TextEntryPoint.WhatsAppEntityWithSamePropertiesExists"),
					{ variant: "error" }
				);
			} else {
				const messageKey = isUpdate ? "UpdateFailed" : "CreateFailed";
				enqueueSnackbar(t(`TextEntryPoint.${messageKey}`), {
					variant: "error",
				});
			}

			setIsSubmitting(false);
			console.error(error);
		}
	};

	const getAssignedType = () => {
		if (!entryPoint.flowId && !entryPoint.queueId) {
			return "" as string | AssignedTypeEnum;
		}

		if (entryPoint.flowId) {
			return AssignedTypeEnum.Flow;
		}

		return AssignedTypeEnum.Queue;
	};

	const getAssignedTo = () => {
		return entryPoint.flowId || entryPoint.queueId || "";
	};

	const handleCancelForm = (handleReset: (e: any) => void, dirty: boolean) => {
		dirty ? handleReset(null) : navigate(route);
		setAccessTokenEditEnabled(false);
	};

	const getSelectMenuItem = (data: any) => {
		return data?.map((type) => (
			<MenuItem key={type.id} value={type.id}>
				{type.name}
			</MenuItem>
		));
	};

	const copyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text);
	};

	const validateDuplicateStatus = (parameters: EmailFolder[]) => {
		if (!parameters || parameters.length == 0) {
			return true;
		}

		const seen = new Set();
		var arrayIsValid = !parameters.some((element: EmailFolder) => {
			return seen.size === seen.add(element.name).size;
		});

		return arrayIsValid;
	};

	return (
		<>
			<Loader
				isOpen={!(isDataLoaded || areAssignmentsLoaded || !entryPointId)}
				isInline={false}
			/>
			<Helmet>
				<title>
					{entryPointId
						? t("TextEntryPoint.EditTextEntryPoint")
						: t("TextEntryPoint.AddTextEntryPoint")}{" "}
					| ContactCenter4All
				</title>
			</Helmet>
			{((isDataLoaded && areAssignmentsLoaded) || !entryPointId) && (
				<Formik
					initialValues={{
						id: entryPointId ? entryPoint?.identifier : "",
						name: entryPointId ? entryPoint?.name : "",
						email: entryPointId ? entryPoint?.email : "",
						type: entryPointId ? entryPoint?.type : ("" as string | TypeEnum),
						assignedType: entryPointId
							? getAssignedType()
							: ("" as string | AssignedTypeEnum),
						assignedTo: entryPointId ? getAssignedTo() : "",
						businessAccountId: entryPointId
							? entryPoint?.businessAccountId
							: "",
						accessToken: entryPointId ? entryPoint?.accessToken : "",
						phoneNumber: entryPointId ? entryPoint?.phoneNumber : "",
						phoneNumberId: entryPoint ? entryPoint?.phoneNumberId : "",
						url: entryPointId ? entryPoint?.url : "",
						processingStartDate: entryPointId
							? dayjs.utc(entryPoint.processingStartDate)
							: getDateNowMinutesPrecision(),
						folders:
							entryPointId && entryPoint?.folders
								? entryPoint?.folders
								: [{ name: EmailEntryPoint.DefaultFolder } as EmailFolder],
					}}
					validationSchema={Yup.object().shape({
						id: Yup.string()
							.trim()
							.uuid(t("TextEntryPoint.InvalidId"))
							.when(["type"], {
								is: TypeEnum.Email.toString(),
								then: Yup.string().required(
									t(MessagingEntryPointTranslationKeys.Required)
								),
								otherwise: (schema) => schema,
							}),
						name: Yup.string()
							.trim()
							.max(128, t(maxNameLengthLabel, { max: 128 }))
							.matches(
								new RegExp(UtilsHelper.NameRegExp),
								t("Shared.SpecialCharacter")
							)
							.required(t(MessagingEntryPointTranslationKeys.Required)),
						email: Yup.string()
							.trim()
							.when(["type"], {
								is: TypeEnum.Email.toString(),
								then: Yup.string()
									.trim()
									.email(t("TextEntryPoint.InvalidEmailAddress"))
									.max(255, t(maxNameLengthLabel, { max: 255 }))
									.required(t(MessagingEntryPointTranslationKeys.Required)),
								otherwise: (schema) => schema,
							}),
						type: Yup.string().required(
							t(MessagingEntryPointTranslationKeys.Required)
						),
						assignedType: Yup.string(),
						assignedTo: Yup.string()
							.trim()
							.when(["assignedType"], {
								is: AssignedTypeEnum.Queue.toString(),
								then: Yup.string().required(
									t(MessagingEntryPointTranslationKeys.Required)
								),
								otherwise: Yup.string().when("assignedType", {
									is: AssignedTypeEnum.Flow.toString(),
									then: Yup.string().required(
										t(MessagingEntryPointTranslationKeys.Required)
									),
									otherwise: (schema) => schema,
								}),
							}),
						phoneNumber: Yup.string()
							.trim()
							.when(["type"], {
								is: TypeEnum.WhatsApp.toString(),
								then: Yup.string().required(
									t(MessagingEntryPointTranslationKeys.Required)
								),
								otherwise: (schema) => schema,
							})
							.max(64, t(maxNameLengthLabel, { max: 64 }))
							.matches(
								UtilsHelper.PhoneNumberRegExp,
								t("BotApplicationInstance.SpecialCharacters")
							),
						phoneNumberId: Yup.string()
							.trim()
							.max(64, t(maxNameLengthLabel, { max: 64 }))
							.matches(
								new RegExp(UtilsHelper.OnlyDigits),
								t(SharedTranslationKeys.NumbersAllowed)
							)
							.when(["type"], {
								is: TypeEnum.WhatsApp.toString(),
								then: Yup.string().required(
									t(MessagingEntryPointTranslationKeys.Required)
								),
								otherwise: (schema) => schema,
							}),
						businessAccountId: Yup.string()
							.trim()
							.matches(
								new RegExp(UtilsHelper.OnlyDigits),
								t(SharedTranslationKeys.NumbersAllowed)
							)
							.max(64, t(maxNameLengthLabel, { max: 64 }))
							.when(["type"], {
								is: TypeEnum.WhatsApp.toString(),
								then: Yup.string().required(
									t(MessagingEntryPointTranslationKeys.Required)
								),
								otherwise: (schema) => schema,
							}),
						accessToken: Yup.string()
							.trim()
							.min(8, t("Settings.MinNameLength", { min: 8 }))
							.max(512, t(maxNameLengthLabel, { max: 8 }))
							.when(["type"], {
								is: TypeEnum.WhatsApp.toString(),
								then: Yup.string().required(
									t(MessagingEntryPointTranslationKeys.Required)
								),
								otherwise: (schema) => schema,
							}),
						folders: Yup.array()
							.of(
								Yup.object().shape({
									name: Yup.string()
										.trim()
										.required(t(MessagingEntryPointTranslationKeys.Required))
										.matches(
											new RegExp(UtilsHelper.EmailFolderRegex),
											t("TextEntryPoint.InvalidFolderPath")
										),
								})
							)
							.test(
								"duplicateValues",
								t("GenericEntryPoint.DuplicateRecords"),
								(values) => validateDuplicateStatus(values)
							),
						processingStartDate: Yup.date().when(["type"], {
							is: TypeEnum.Email.toString(),
							then: Yup.date()
								.typeError(t("Event.InvalidFormat"))
								.required(t(MessagingEntryPointTranslationKeys.Required)),
							otherwise: (schema) => schema,
						}),
					})}
					onSubmit={(values) => {
						const textEntryPoint: TextEntryPoint = {
							identifier: values.id,
							name: values.name,
							email: values.email,
							queueId:
								values.assignedType === AssignedTypeEnum.Queue
									? values.assignedTo
									: null,
							flowId:
								values.assignedType === AssignedTypeEnum.Flow
									? values.assignedTo
									: null,
							businessAccountId: values.businessAccountId,
							accessToken: values.accessToken,
							phoneNumberId: values.phoneNumberId,
							phoneNumber: values.phoneNumber,
							url: `${apiConfig.url}/v1/whatsapp/webhook/${tenantId}`,
							type: +values.type,
							processingStartDate: values.processingStartDate
								.utc()
								.format("YYYY-MM-DDTHH:mm:ss[Z]"),
							folders: values.folders,
						};

						let isUpdate = false;

						if (entryPointId) {
							textEntryPoint.id = entryPointId;
							isUpdate = true;
						}
						if (textEntryPoint.type == TypeEnum.Email) {
							addOrUpdateEmailEntryPoint(textEntryPoint, isUpdate);
						} else {
							addOrUpdateWhatsappEntryPoint(textEntryPoint, isUpdate);
						}
					}}
				>
					{({
						errors,
						handleBlur,
						handleChange,
						handleSubmit,
						handleReset,
						setFieldValue,
						touched,
						values,
						dirty,
					}): JSX.Element => (
						<form onSubmit={handleSubmit}>
							<ChangesFormActions
								isValid={true}
								isSubmitting={isSubmitting}
								handleReset={() => handleCancelForm(handleReset, dirty)}
								isSaveAction={false}
							/>
							<Grid container spacing={2} className={classes.textEntryPoint}>
								<Grid item xs={5}>
									<CustomTextField
										size="small"
										color="info"
										error={Boolean(touched.name && errors.name)}
										fullWidth
										helperText={touched.name && errors.name}
										label={t("TextEntryPoint.Name")}
										name="name"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.name}
										variant="outlined"
										data-testid="name"
									/>
								</Grid>
								<Grid item xs={2.5}>
									<FormControl fullWidth>
										<InputLabel
											id="type-select-label"
											className={classes.selectComponentLabel}
										>
											{t("TextEntryPoint.Type")}
										</InputLabel>
										<Select
											value={values.type}
											error={Boolean(touched.type && errors.type)}
											label={t("TextEntryPoint.Type")}
											onChange={(event) => {
												const selectedValue = event.target.value;
												if (selectedValue) {
													const selectedEnum = event.target.value as TypeEnum;
													getAssignableQueues(selectedEnum);
													assignedType.current = selectedEnum;
												}

												handleChange(event);
											}}
											size="small"
											name="type"
											className={classes.selectComponent}
											data-testid="type"
											disabled={entryPointId && values.type !== ""}
										>
											<MenuItem value="">
												<ListItemText primary="Select" />
											</MenuItem>
											{entryPointTypes.map((ept: string) => (
												<MenuItem key={ept} value={ept}>
													{TypeEnum[ept]}
												</MenuItem>
											))}
										</Select>
										{Boolean(touched.type && errors.type) && (
											<FormHelperText error>
												{t(MessagingEntryPointTranslationKeys.Required)}
											</FormHelperText>
										)}
									</FormControl>
								</Grid>
								<Grid item xs={4} />
								{values.type !== "" &&
									((values.type === TypeEnum.Email && (
										<Grid item xs={5}>
											<CustomTextField
												size="small"
												color="info"
												error={Boolean(touched.id && errors.id)}
												fullWidth
												helperText={touched.id && errors.id}
												label={t("TextEntryPoint.Id")}
												name="id"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.id}
												variant="outlined"
												data-testid="id"
											/>
										</Grid>
									)) ||
										(values.type === TypeEnum.WhatsApp && (
											<Grid item xs={5}>
												<CustomTextField
													size="small"
													color="info"
													error={Boolean(
														touched.businessAccountId &&
															errors.businessAccountId
													)}
													fullWidth
													helperText={
														touched.businessAccountId &&
														errors.businessAccountId
													}
													label={t("TextEntryPoint.BusinessAccountId")}
													name="businessAccountId"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.businessAccountId}
													variant="outlined"
													data-testid="businessAccountId"
												/>
											</Grid>
										)))}
								<Grid item xs={5}>
									{values.type === TypeEnum.Email && (
										<CustomTextField
											size="small"
											color="info"
											error={Boolean(touched.email && errors.email)}
											fullWidth
											helperText={touched.email && errors.email}
											label={t("TextEntryPoint.EmailAddress")}
											name="email"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.email}
											variant="outlined"
											data-testid="email"
										/>
									)}
									{values.type === TypeEnum.WhatsApp && (
										<CustomTextField
											size="small"
											color="info"
											error={Boolean(touched.accessToken && errors.accessToken)}
											fullWidth
											helperText={touched.accessToken && errors.accessToken}
											label={t("TextEntryPoint.AccessToken")}
											name="accessToken"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.accessToken}
											variant="outlined"
											data-testid="accessToken"
											id="accessToken"
											disabled={!accessTokenEditEnabled}
											InputProps={{
												readOnly: !accessTokenEditEnabled,
											}}
										/>
									)}
								</Grid>
								{values.type === TypeEnum.WhatsApp && entryPointId && (
									<Grid
										item
										xs={1}
										className={classes.genericEntryPointRemoveButton}
									>
										<IconButton
											className={classes.deleteButton}
											onClick={() => {
												setFieldValue("accessToken", "");
												if (!accessTokenEditEnabled) {
													setAccessTokenEditEnabled(true);
												} else {
													setAccessTokenEditEnabled(false);
													handleReset();
												}
											}}
											title={
												accessTokenEditEnabled
													? t("TextEntryPoint.UndoAccessToken")
													: t("TextEntryPoint.EditAccessToken")
											}
										>
											{!accessTokenEditEnabled && <EditIcon />}
											{accessTokenEditEnabled && <SettingsBackupRestoreIcon />}
										</IconButton>
									</Grid>
								)}
								{values.type === TypeEnum.WhatsApp && (
									<WhatsappPhoneNumberFields />
								)}
								{values.type !== "" && (
									<>
										{values.type === TypeEnum.Email && (
											<Grid item xs={2}></Grid>
										)}
										<Grid item xs={2.5}>
											<FormControl fullWidth>
												<InputLabel
													id="assigned-type-select-label"
													className={classes.selectComponentLabel}
												>
													{t("TextEntryPoint.AssignedType")}
												</InputLabel>
												<Select
													value={values.assignedType}
													error={Boolean(
														touched.assignedType && errors.assignedType
													)}
													label={t("TextEntryPoint.AssignedType")}
													onChange={handleChange}
													size="small"
													name="assignedType"
													className={classes.selectComponent}
													data-testid="assignedType"
												>
													<MenuItem value="">
														<ListItemText primary="Select" />
													</MenuItem>
													{assignedTypes.map((at: number) => (
														<MenuItem key={at} value={at}>
															{t(TextEntryPointAssignedTypeToLabelMapping[at])}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={2.5}>
											<FormControl fullWidth>
												<InputLabel
													id="assigned-to-select-label"
													className={classes.selectComponentLabel}
												>
													{t("TextEntryPoint.AssignedTo")}
												</InputLabel>
												<Select
													value={
														values.assignedType === "" ? "" : values.assignedTo
													}
													error={Boolean(
														touched.assignedTo && errors.assignedTo
													)}
													label={t("TextEntryPoint.AssignedTo")}
													onChange={handleChange}
													size="small"
													name="assignedTo"
													disabled={
														values.assignedType === "" || values.type === ""
													}
													className={classes.selectComponent}
													data-testid="assignedTo"
												>
													<MenuItem value="">
														<ListItemText primary="Select" />
													</MenuItem>
													{values.assignedType === AssignedTypeEnum.Queue
														? getSelectMenuItem(allAssignToData.queues)
														: getSelectMenuItem(allAssignToData.flows)}
												</Select>
												{Boolean(touched.assignedTo && errors.assignedTo) && (
													<FormHelperText error>
														{t(MessagingEntryPointTranslationKeys.Required)}
													</FormHelperText>
												)}
											</FormControl>
										</Grid>
										{values.type === TypeEnum.WhatsApp && (
											<>
												<Grid item xs={5}>
													<CustomTextField
														size="small"
														color="info"
														error={Boolean(touched.url && errors.url)}
														fullWidth
														helperText={touched.url && errors.url}
														label={t("GenericEntryPoint.EntryPointUrl")}
														name="url"
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.url}
														variant="outlined"
														data-testid="url"
														id="url"
														disabled
														InputProps={{
															readOnly: true,
														}}
													/>
												</Grid>
												<Grid
													item
													xs={1}
													className={classes.genericEntryPointRemoveButton}
												>
													<IconButton
														className={classes.deleteButton}
														onClick={() => copyToClipboard(values.url)}
														title={t("GenericEntryPoint.CopyToClipboard")}
													>
														<ContentCopyIcon />
													</IconButton>
												</Grid>
											</>
										)}
									</>
								)}
								{values.type === TypeEnum.Email && (
									<Grid item xs={10}>
										<CommonEntryPointBox
											label={t("TextEntryPoint.InboxSettings")}
											id="email-entry-point-box"
											InputProps={{
												inputComponent: TextEntryPointInboxSettingsContainer,
												inputProps: {
													entryPointMappingsContainerProps: {
														processingStartDate: values.processingStartDate,
														handleChange: handleChange,
														touched: touched,
														errors: errors,
														folders: values.folders,
													},
												},
												readOnly: true,
											}}
											InputLabelProps={{ shrink: true }}
											className={classes.entryPointBox}
										/>
									</Grid>
								)}
							</Grid>
						</form>
					)}
				</Formik>
			)}
		</>
	);
};

export default AddEditTextEntryPoint;
